<template>
  <b-overlay :show="show2" rounded="sm" no-fade>
    <div class="m-4">
      <b-row>
        <b-col lg="12" class="align-items-center mr-auto">
          <b-card>
            <div align="center">
              <h3>{{ hotspot.title }}</h3>
              <h3>{{ mac }} <br /></h3> วันเริ่มต้น {{ fromDate }} วันที่สิ้นสุด {{ toDate }}</h3>
            </div>
            <div>
              <b-dropdown :text="datelabel" variant="success" class="chart-dropdown" toggle-class="p-50">
                <b-dropdown-item @click="selectdate(0)">
                  <h4>วันนี้</h4>
                </b-dropdown-item>
                <b-dropdown-item @click="selectdate(1)">
                  <h4>เมื่อวาน</h4>
                </b-dropdown-item>
                <b-dropdown-item @click="selectdate(2)">
                  <h4>7 วันที่ผ่านมา</h4>
                </b-dropdown-item>
                <b-dropdown-item @click="selectdate(3)">
                  <h4>30 วันที่ผ่านมา</h4>
                </b-dropdown-item>
                <b-dropdown-item @click="selectdate(4)">
                  <h4>เดือนนี้</h4>
                </b-dropdown-item></b-dropdown-item>
                <b-dropdown-item @click="selectdate(5)">
                  <h4>กำหนดเอง</h4>
                </b-dropdown-item>
              </b-dropdown>
              <div v-if="customdate">
                <h5 class="mt-1">ระบุช่วงวันที่</h5>
                <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }" @input="onDateChange" />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="user-chats scroll-area" style="height: 450px;">
        <div>

          <div v-for="(items, date) in data" :key="date">
            <h2>{{ date }}</h2>
            <ul>
              <li v-for="(item, index) in items" :key="index">
                {{ item.status }} <sapn class="mr-2"> </sapn>{{ item.create_datatime }}
              </li>
              <hr>
            </ul>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </b-overlay>
</template>

<script>
import api from "@/api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import flatPickr from "vue-flatpickr-component";
import {

  BBadge,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BButton,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
export default {
  components: {
    flatPickr,
    VuePerfectScrollbar,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BButton,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      show2: true,
      i: 1,
      mac: "",
      daterange: 2,
      hotspot: [],
      data: [],
      datelabel: "กำหนดวันที่ 7 วันที่ผ่านมา",
      fromDate: "",
      toDate: "",
      customdate: false,
      rangeDate: null,
    };
  },

  beforeMount() {
    this.selectdate(this.daterange);
  },
  mounted() {

    // this.loadData();
  },
  methods: {
    selectdate(daterange) {
      this.loaded = false;
      this.daterange = daterange;
      // alert(daterange);
      var today = this.$moment();

      //  console.log(daterange);
      if (daterange == 0) {
        this.customdate = false;
        this.datelabel = "กำหนดวันที่ วันนี้";
        this.fromDate = today.format("YYYY-MM-DD");
        this.toDate = today.format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 1) {
        this.customdate = false;
        this.datelabel = "กำหนดวันที่ เมื่อวาน";

        this.fromDate = today.subtract(1, "days").format("YYYY-MM-DD");
        this.toDate = this.fromDate;
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 2) {
        this.datelabel = "กำหนดวันที่ 7 วันที่ผ่านมา";

        this.fromDate = today.subtract(7, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 3) {
        this.customdate = false;

        this.datelabel = "กำหนดวันที่ 30 วันที่ผ่านมา";

        this.fromDate = today.subtract(30, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 4) {
        this.customdate = false;

        this.datelabel = "กำหนดวันที่ เดือนนี้";

        this.fromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
        this.toDate = this.$moment().endOf("month").format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 5) {
        this.customdate = true;
        this.datelabel = "กำหนดวันที่ กำหนดเอง";


        this.fromDate = null;
        this.toDate = null;

        console.log(this.fromDate);
        console.log(this.toDate);
      } else {
        this.fromDate = null;
        this.toDate = null;
      }

      // console.log(currentDateWithFormat);
    },
    onDateChange() {
      console.log(this.rangeDate);
      if (this.rangeDate.search("to") > 0) {
        const myArr = this.rangeDate.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        this.$nextTick(() => {
          this.loadData();
        });
      }
    },


    loadData() {
      this.show2 = true
      this.mac = this.$route.query.mac;
      console.log("this.mac :>> ", this.mac);
      const params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
        mac: this.mac,
      };
      console.log("params :>> ", params);
      api
        .post("ap_detail", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.data = response.data.data
          this.hotspot = response.data.hotspot
          this.show2 = false
        })
        .catch((error) => { this.show2 = false });
    },
  },
};
</script>


<style lang="scss">
.dropdown-toggle {
  font-size: 18px !important;
  border-style: solid !important;

}

section.ps-container.user-chats.scroll-area.ps.ps--active-y {

  padding: 20px;
  border-radius: 20px;
  font-size: 20px;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
